<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center col-md-5">
                        <div class="font-weight-bold body-1">
                            Editar Pago
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="5">
                        <v-form ref="form" @submit.prevent="save">
                            <item-form ref="PaymentForm" :values="form" @save="save" :serverErrors="serverErrors" :edit="true"></item-form>
                        </v-form>
                    </v-col>
                    
                </v-row>
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="5" class="">
                    <v-row justify="end" class="px-3 py-3">
                        <secondary-button data-cy="cancel-btn" :props="cancelBtnData" class="mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button>
                        <primary-button data-cy="accept-btn" :props="saveBtnData" class=""></primary-button>  
                    </v-row>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import Object2Form from '../../../../helpers/Object2Form';
import Utils from '../../../../helpers/Utils';
import PaymentForm from "./Form.vue";
export default {
    components: {
        'item-form': PaymentForm,
    },
	data(){
		return {
            saleOrderId: this.$route.params.saleOrderId,
            paymentId: this.$route.params.paymentId,
            loading: true,
            serverErrors: {},
           
            form: {
                date: "",
                amount: "",
                bank_id: "",
                bank_account_id: "",
                invoice_number: "",
                invoice_file: [],
                invoice_url: "",



                
            },
            cancelBtnData: {
                text: "Cancelar",
                icon: "",
                to: "SaleOrdersDetails",
                block:false,
                click: () => {}
            },
            saveBtnData: {
                text: "Guardar",
                icon: "",	
                click: this.save,
                block:false,
                loading: false,
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
        this.index();
    },
	methods: {

       
        onResize () {
            
            if(window.innerWidth < 600){
                this.saveBtnData.block = true;
                this.cancelBtnData.block = true;
            }
            else{
                this.saveBtnData.block = false;
                this.cancelBtnData.block = false;
            }
        },
        index(){
            this.loading = true;
            this.$store.state.loading = true;
            this.$api.saleOrder.getPayment(this.saleOrderId, this.paymentId)
                .then((resp) => {
                    this.form.date = resp.data.date;
                    this.form.amount = resp.data.amount;
                    this.form.bank_id = resp.data.bank.id;
                    this.form.bank_account_id = resp.data.bank_account.id;
                    this.form.invoice_number = resp.data.invoice_number;
                    this.form.invoice_url = resp.data.invoice_url;


                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        },
        
		validate () {
            if(!Utils.objectIsEmpty(this.serverErrors))
                return false;
            // if(this.form.products.length == 0)
            //     return false;
			return this.$refs.form.validate();
            // return true;
		},
        save(){
            
            console.log(this.form);
            
            if(this.validate()){
                
                let form = this.prepareRequest(this.form);
                this.saveBtnData.loading = true;
                this.$store.state.loading = true;
                this.$api.saleOrder.updatePayment(this.saleOrderId, this.paymentId, form)
                    .then(() => {
                        //console.log(response)

                        this.$store.dispatch('snackbarSuccess', `Se ha actualizado correctamente`);
                        this.$router.push({name: 'SaleOrdersDetails'});
                    })
                    .catch((error) => {
                        var errors = []
                        switch(error.response.status){
                            case 422: 
                                
                                if(("error" in error.response.data)){
                                    if(error.response.data.error instanceof Object){
                                        Object.keys(error.response.data.error).forEach((x) => {
                                            
                                               
                                            console.log(x);
                                            this.serverErrors = error.response.data.error;
                                            error.response.data.error[x].forEach((y) => {
                                                this.serverErrors[x] = Utils.deleteWord(x,y);
                                                errors.push(Utils.deleteWord(x,y))
                                            })
                                        });
                                    }else{
                                        errors.push(error.response.data.error)
                                    }
                                    
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;

                            case 500:
                                if(("error" in error.response.data)){
                                    if(error.response.data.error == "PAYMENT_OVERFLOW"){
                                        this.$store.dispatch('snackbarError', "El pago sobrepasa el saldo");
                                    }
                                }
                            break;
                           
                        }
                        console.log(this.serverErrors)
                       
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                    })
                ;
            }else{
                
                this.$store.dispatch('snackbarError', `Formulario incompleto`);
            }
            // eslint-disable-next-line no-console
        },
        prepareRequest(request)
        {
            let data = JSON.parse(JSON.stringify(request));

            data.invoice_file = request.invoice_file;

            if(request.invoice_url == '' && (request.invoice_file instanceof Array))
                data.invoice_file = null;

            if(request.invoice_url != '' && (request.invoice_file instanceof Array))
                delete data.invoice_file;
           

            return Object2Form.objectToFormData(data, "", []);
        }
    },
}
</script>

<style>

</style>